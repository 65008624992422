import { useEffect, useState } from 'react';
import cookie from 'react-cookies';

import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

// Firebase 구성 객체 (Firebase Console에서 발급받은 값으로 대체)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Firebase 앱과 Firestore 초기화
const app = initializeApp(firebaseConfig);
const db_global_doc = doc(getFirestore(app), 'phw_lab', 'global');

export default function VisitorCount() {
  const [count, setCount] = useState(null);

  useEffect(() => {
    // cookie.remove('user_id');
    let user_id = cookie.load('user_id');
    // 만약 존재하지 않는다면 (새로운 방문자)
    if (user_id === undefined) {
      // console.log(user_id + ' 유저 신규')
      // 고유 방문자 ID 생성 및 쿠키에 저장
      user_id = uuidv4();
      const expires = new Date() 
      expires.setSeconds(expires.getSeconds() + 5) // 5초마다 초기화
      // expires.setMinutes(expires.getMinutes() + 60) // 1시간마다 초기화
      cookie.save('user_id', user_id, {
          path : '/',
          expires,
          // secure : true,
          // httpOnly : true
      });
      updateAndGetVisitorCount();
    } else {
      // console.log(user_id + ' 유저 존재')
      getVisitorCount();
    }
  }, []);

  async function updateAndGetVisitorCount() {
    try {
      const docSnap = await getDoc(db_global_doc);
      if (docSnap.exists()) {
        await updateDoc(db_global_doc, { visitor_count: increment(1) });
      } else {
        await setDoc(db_global_doc, { visitor_count: 1 });
      }
      const updatedSnap = await getDoc(db_global_doc);
      setCount(updatedSnap.data().visitor_count);
    } catch (error) {
      console.error("방문자 수 업데이트 실패:", error);
    }
  }

  async function getVisitorCount() {
    try {
      const docSnap = await getDoc(db_global_doc);
      if (docSnap.exists()) {
        setCount(docSnap.data().visitor_count);
      } else {
        setCount(0);
      }
    } catch (error) {
      console.error("방문자 수 조회 실패:", error);
    }
  }

  return (
    <div className='text-right'>{count !== null ? count : "…"}</div>
  );
}
